import Api from '@/core/services/Api';
import getSkillList from '@/core/static-json/curriculumSetV2';
import getFactFluencyList from '@/core/static-json/factFluencySet';
import gameTypes from '@/core/static-json/gameTypes';
import { uniqBy, isEqual } from 'lodash';
import {
    convertObjectToUrlQueryParams,
    firstToUpper,
} from '@/core/helpers/utils';
import { i18n } from '@/lang/translator';
import { app } from '@/main';
import SecureApi from '@/flows/Authentication/services/SecureApi.js';
import CONSTANTS from '@/core/helpers/constants.js';

/**
 * Copied from GameTypeNames:methods:getGameTypeEquationIcon
 * Finds equationType by type.
 * @param gameTypeString
 * @returns {*|string}
 */
function getGameTypeEquationIcon(gameTypeString) {
    const currentGameType = gameTypes.find(
        (gameType) => gameType.type === gameTypeString,
    );
    return currentGameType?.equationType || '';
}

export default {
    namespaced: true,
    state: {
        initialized: false,
        loading: false,
        loaded: false,
        gradesList: [1, 2, 3, 4, 5, 6, 7],
        classList: [],
        displayClassList: [],
        classPages: {},
        reports: [],
        currentReport: null,
        reportCategories: {},
        skillsReports: [],
        referrals: [],
        skillsList: getSkillList,
        faceFluencyList: getFactFluencyList,
        gameTypes: gameTypes,
        activeClass: null,
        activeGrade: 1,
        selectedClass: null,
        hasNotSeenReports: false,
        /**
         * Array of category keys in the format `group::category`
         */
        selectedTblCategories: [],
        /**
         * Array of skill keys in the format `topic::skill`
         */
        selectedTblSkills: [],
    },
    getters: {
        // rootGetters shortcuts
        user: (state, getters, rootState, rootGetters) => rootGetters.user,
        usUser: (state, getters, rootState, rootGetters) =>
            rootGetters['v2/user/isFromUS'],
        // teacher-related getters
        sortedSkillsReports: (state) =>
            uniqBy(state.skillsReports, '_id').sort(
                (a, b) => new Date(b.updated) - new Date(a.updated),
            ),
        reports: (state) => state.reports || [],
        report: (state, getters) => (code) => {
            const report = getters.reports.find((r) => r.code === code);

            if (!report && state.currentReport?.code === code)
                return state.currentReport;

            return report;
        },
        /**
         * @deprecated Does nothing but returning a state
         * todo: consider removal
         * @returns {object} Active class object
         */
        activeClass: (state) => state.activeClass || null,
        isCleverClass: (state) => !!state.activeClass?.sectionId,
        isGoogleClass: (state) => !!state.activeClass?.courseId,
        activeGrades: (state) => {
            return Array.from(state.gradesList).map((item) => {
                const labelName =
                    Number(item) < 7 ? `Grade ${item}` : `Grades 7-12`;

                return {
                    label: labelName,
                    grade: Number(item),
                };
            });
        },
        lowestGrade: (state, getters) => {
            const user = getters.user;

            if (!user || !user.teachingGrades) {
                return 1;
            }

            const teachingGrades = user.teachingGrades.filter((grade) =>
                state.gradesList.includes(Number(grade)),
            );

            if (teachingGrades.length) {
                const highestGrade = teachingGrades
                    .sort((a, b) => Number(b) - Number(a))
                    .pop();

                if (Number(highestGrade) < 7) {
                    return Number(highestGrade);
                } else {
                    if (teachingGrades.length > 1) {
                        return 1;
                    } else {
                        return Number(highestGrade);
                    }
                }
            }

            if (
                user.teachingGrades.length &&
                !user.teachingGrades.includes('K')
            ) {
                return 7;
            }

            return 1;
        },
        selfPacedGamesPlayed: (state, getters) => {
            const user = getters.user;

            return user?.selfPacedGamesPlayed || 0;
        },
        liveGamesPlayed: (state, getters) => {
            const user = getters.user;

            return user?.liveGamesPlayed || 0;
        },
        selfPacedGamesMin4PlayersPlayed: (state, getters) => {
            const user = getters.user;

            return user?.selfPacedGamesMin4PlayersPlayed || 0;
        },
        liveGamesMin4PlayersPlayed: (state, getters) => {
            const user = getters.user;

            return user?.liveGamesMin4PlayersPlayed || 0;
        },
        gamesPlayedWithStudents: (state, getters) => {
            return (
                getters.liveGamesMin4PlayersPlayed +
                getters.selfPacedGamesMin4PlayersPlayed
            );
        },
        gamesPlayedTotalCount: (state, getters) => {
            return getters.liveGamesPlayed + getters.selfPacedGamesPlayed;
        },
        hasUnseenReports: (state) => {
            return state.hasNotSeenReports;
        },
        isOnboarding: (state, getters) => {
            return getters.liveGamesMin4PlayersPlayed < 3;
        },
        hasReferralNotifications: (state, getters, rootState, rootGetters) => {
            if (!rootGetters['v2/user/isFromUS']) {
                return false;
            }

            return (
                state.referrals.length &&
                ((!localStorage.getItem('referralCount') &&
                    state.referrals.length) ||
                    (!isNaN(
                        parseInt(localStorage.getItem('referralCount'), 10),
                    ) &&
                        parseInt(localStorage.getItem('referralCount'), 10) !==
                            state.referrals.length))
            );
        },
        gameType: (state) => (typeKey) => {
            if (
                typeKey === 'TYPE_MULTI_TOPICS' ||
                typeKey === 'MULTI_TOPICS_TYPE'
            ) {
                return typeKey;
            }

            return (
                state.gameTypes.findLast(
                    (gameType) => gameType.type === typeKey,
                ) || null
            );
        },
        /**
         * Skill model has 3 nested levels.
         *
         * Group level. Math facts (fact-fluency) and Grade (skill-list) groups.
         *
         * Category level. Both groups contain skills divided in categories ('Addition').
         *
         * Topic level. Category skills may be grouped in one or several topics.
         * Inside the topic there are particular skills.
         */
        skillList: (state, getters) => {
            if (!getters.usUser) {
                return [];
            }

            const categories = getSkillList.filter(
                (item) => Number(item.grade) === Number(state.activeGrade),
            );

            const skillListArray = [
                {
                    group: 'fact-fluency',
                    skills: [...getFactFluencyList],
                },
                {
                    group: 'skill-list',
                    skills: [...(categories[0] || getSkillList[0]).categories],
                },
            ];

            return skillListArray.map((groupItem) => {
                return {
                    group: groupItem.group,
                    skills: groupItem.skills.map((category) => {
                        return {
                            category: category.category,
                            categoryLabel: category.categoryLabel
                                ? category.categoryLabel
                                : null,
                            categoryIcon: category.categoryIcon
                                ? category.categoryIcon
                                : null,
                            type: category.type ? category.type : null,
                            topics: category.topics
                                .map((topic) => {
                                    return {
                                        topic: topic.topic,
                                        custom: topic.custom,
                                        skills: topic.skills.map((skill) => {
                                            return {
                                                name: skill.name,
                                                type: skill.type,
                                                numberGenerator:
                                                    skill.numberGenerator,
                                            };
                                        }),
                                    };
                                })
                                .filter((topic) => topic.skills.length !== 0)
                                .flat(),
                        };
                    }),
                };
            });
        },
        /**
         * Returns `skillList` as a map where key is '<group>::<category>'
         * and the value is the corresponding `topics` array.
         * Enriches topic and skill level objects with the parent level info
         * to trace back/up.
         */
        topicsByGroupAndCategoryMap: (state, getters) => {
            const skillList = getters.skillList;
            const topics = {};
            (skillList || []).forEach((group) =>
                (group.skills || []).forEach((category) => {
                    topics[`${group.group}::${category.category}`] = (
                        category.topics || []
                    ).map((topic) => ({
                        ...topic,
                        skills: topic.skills.map((skill) => ({
                            ...skill,
                            topic: topic.topic,
                            category: category.category,
                            group: group.group,
                            key: `${topic.topic}::${skill.name}`,
                        })),
                        category: category.category,
                        categoryIcon: category.categoryIcon,
                        group: group.group,
                    }));
                }),
            );
            return topics;
        },
        /**
         * @deprecated Does nothing but returning a state
         * todo: consider removal
         * @returns {number} Active grade
         */
        activeGrade: (state) => {
            return state.activeGrade;
        },
        selectedClass: (state) => {
            return state.selectedClass;
        },
        showTrySoloMode: (state, getters, rootState, rootGetters) => {
            const user = getters.user;

            const currentSoloTrack =
                rootGetters['v2/soloTrack/currentSoloTrack'];

            return (
                user?.role === 'teacher' &&
                (user?.premium?.status === 'active' ||
                    user?.premium?.status === 'trial') &&
                user?.liveGamesMin4PlayersPlayed >= 3 &&
                !user?.flags?.clickedGetStarted &&
                !user?.flags?.clickedSoloMode3 &&
                (state.classList?.length === 0 ||
                    !currentSoloTrack.find((track) =>
                        track.trackedTopics?.find(
                            (topic) => topic.solvedProblems > 0,
                        ),
                    ))
            );
        },
        cleverAuthLink: () => {
            const baseUrl = 'https://clever.com/oauth/authorize';

            const origin = window.location.origin;

            const redirectUrl = `${origin}?fromClever=true`;

            const district =
                window.location.hostname === 'localhost'
                    ? `&district_id=${import.meta.env.VITE_CLEVER_DISTRICT_ID}`
                    : '';

            const queryUrl = `response_type=code&redirect_uri=${encodeURIComponent(
                redirectUrl,
            )}&client_id=${import.meta.env.VITE_CLEVER_CLIENT_ID}${district}`;

            return `${baseUrl}?${queryUrl}`;
        },
        hideSchoolPracticeWelcome: () => {
            return () => !!sessionStorage.getItem('hideSchoolPracticeWelcome');
        },
    },
    mutations: {
        initialized: (state, next) => {
            state.initialized = !!next;
        },
        loading: (state, next) => {
            state.loading = !!next;
        },
        loaded: (state, next) => {
            state.loaded = !!next;
        },
        classList: (state, next) => {
            state.classList = next || [];
        },
        updateClassInList: (state, next) => {
            const updatedClassIdx = state.classList?.findIndex(
                (el) => el.classCode === next.classCode,
            );

            state.classList[updatedClassIdx] = next;
        },
        displayClassList: (state, next) => {
            state.displayClassList = next || [];
        },
        reports: (state, next) => {
            state.reports = next || [];
        },
        currentReport: (state, next) => {
            state.currentReport = next || null;
        },
        hasNotSeenReports: (state, next) => {
            state.hasNotSeenReports = next;
        },
        updateReport: (state, next) => {
            const idx = state.reports.findIndex((r) => r.code === next.code);

            if (idx === -1) return;

            state.reports[idx] = next;
        },
        reportCategories: (state, next) => {
            state.reportCategories = next || {};
        },
        skillsReports: (state, next) => {
            state.skillsReports = next || [];
        },
        referrals: (state, next) => {
            state.referrals = next || [];
        },
        activeGrade: (state, next) => {
            state.activeGrade = next;

            const gradeSaved = sessionStorage.getItem('gradeSaved');

            if (!gradeSaved || gradeSaved === '0') {
                sessionStorage.setItem('gradeSaved', state.activeGrade);
            }
        },
        activeClass: (state, next) => {
            state.activeClass = next || null;
        },
        classPage: (state, { classCode, page }) => {
            state.classPages[classCode] = page;
        },
        markReferralNotificationsReaded: (state, readCount) => {
            localStorage.setItem('referralCount', readCount);
        },
        setSelectedClass: (state, next) => {
            state.selectedClass = next;
        },
        setSelectedTblCategories: (state, next) => {
            state.selectedTblCategories = next;
        },
        setSelectedTblSkills: (state, next) => {
            state.selectedTblSkills = next;
        },
        resetStateToDefault: (state) => {
            state.initialized = false;
            state.loading = false;
            state.loaded = false;
            state.gradesList = [1, 2, 3, 4, 5, 6, 7];
            state.classList = [];
            state.displayClassList = [];
            state.classPages = {};
            state.reports = [];
            state.currentReport = null;
            state.reportCategories = {};
            state.skillsReports = [];
            state.referrals = [];
            state.skillsList = getSkillList;
            state.faceFluencyList = getFactFluencyList;
            state.gameTypes = gameTypes;
            state.activeClass = null;
            state.activeGrade = 1;
            state.selectedClass = null;
            state.hasNotSeenReports = false;
            state.selectedTblCategories = [];
            state.selectedTblSkills = [];
        },
    },
    actions: {
        init: async (store) => {
            // already initialized
            if (store.state.initialized) return;
            if (store.state.loading) return;

            console.log('store::teacher::init');

            store.commit('loading', true);

            // loading classlist 1st
            await store.dispatch('loadClassLists', { skipLocalCache: true });
            // load active class
            await store.dispatch('loadActiveClass');

            // active grade
            const activeGrade = sessionStorage.getItem('gradeSaved')
                ? Number(sessionStorage.getItem('gradeSaved'))
                : store.state.activeClass
                  ? [1, 2, 3, 4, 5, 6, 7].includes(
                        Number(store.state.activeClass.grade),
                    )
                      ? Number(store.state.activeClass.grade)
                      : Number(store.state.getLowestGradeSet)
                  : Number(store.state.getLowestGradeSet);

            await store.dispatch('setActiveGrade', activeGrade);

            store.commit('initialized', true);
            store.commit('loading', false);

            console.log('store::teacher::initialized');
        },
        reset: (store) => {
            console.log('store::teacher::reset');

            store.commit('resetStateToDefault');
        },
        loadCache: (store) => {
            console.log('store::teacher::loadCache');
        },
        saveCache: (store) => {
            console.log('store::teacher::saveCache');
        },
        clearCache: (store) => {
            console.log('store::teacher::clearCache');
        },
        loadReferrals: async (store) => {
            console.log('store::teacher::loadReferrals');
        },
        setActiveGrade: async (store, grade) => {
            store.commit('activeGrade', grade);

            // store.dispatch('setActiveGrade', grade);

            await store.dispatch('reloadSkillReports');
        },
        /**
         * Fetches class list for the teacher
         * @param store
         * @param checkForGames todo: provide description
         * @param skipLocalCache set `true` to skip cache and reload from server
         * @param includeShared set `false` to exclude co-teaching classes
         */
        loadClassLists: async (
            store,
            {
                checkForGames = false,
                skipLocalCache = false,
                includeShared = true,
            } = {},
        ) => {
            /**
             * Stores display class list that also contains "No class" item
             */
            function storeDisplayList(classList) {
                const displayList = [classList].push({
                    classCode: '-1',
                    className: i18n.t('general.noClass'),
                    grade: store.state.activeGrade,
                });
                store.commit('displayClassList', displayList);
            }

            // checking classes localStorage cache for user id.
            // return cache if exists, else do API request
            let list = [];

            const uid = store.getters.user ? store.getters.user.userId : null;

            if (!uid) {
                console.error(
                    'store::teacher::loadClassLists error: no uid',
                    uid,
                );
            }

            if (
                !checkForGames &&
                uid &&
                sessionStorage.getItem(`getClassList/${uid}`) &&
                !skipLocalCache
            ) {
                // store tech class list
                list = JSON.parse(
                    sessionStorage.getItem(`getClassList/${uid}`),
                );
                store.commit('classList', list);
                storeDisplayList(list);
                return;
            }

            let response = null;

            try {
                response = await Api().get(
                    `class-lists?includeShared=${includeShared}${
                        checkForGames ? '&checkForGames=true' : ''
                    }`,
                );
            } catch (err) {
                console.error('store::teacher::loadClassLists error: ', err);
                response = null;
            }
            if (!response || !response.data.success || !response.data.data) {
                console.error('store::teacher::loadClassLists - no classes');
                return;
            }

            list = response.data.data;
            storeDisplayList(list);

            // cache results on success
            if (uid) {
                sessionStorage.setItem(
                    `getClassList/${uid}`,
                    JSON.stringify(response.data.data),
                );
            }

            store.commit('classList', list);

            // this will drop active class if left from another user
            store.dispatch(
                'setActiveClass',
                store.state.activeClass?.classCode,
            );
        },
        loadClassPage: async (store, classCode) => {
            if (!classCode) {
                return null;
            }

            let response = null;

            try {
                response = await Api().get(
                    `home-game-v10SimpleTreeSocial/class/${classCode}`,
                );
            } catch (err) {
                console.error('store::teacher::fetchClassPage error', err);

                response = null;
            }

            if (!response) {
                return null;
            }

            const { data, success } = response.data;

            if (success) {
                store.commit('classPage', { classCode, page: data });

                store.commit('setSelectedClass', data);
            }

            return data;
        },
        loadPlayedGamesTypes: async (store, { shared = false } = {}) => {
            const user = store.getters.user;

            if (!user) {
                return null;
            }

            let response = null;

            try {
                response = await Api().get(
                    `game-reports/reports/game-types?shared=${shared}`,
                );
            } catch (err) {
                console.error(
                    'store::teacher::loadPlayedGamesTypes error: ',
                    err,
                );
                response = null;
            }

            if (!response) {
                return null;
            }

            const { success, data } = response.data;

            if (success) {
                store.commit('reportCategories', data);
            }
        },
        loadReports: async (
            store,
            {
                page = 1,
                pageSize = 15,
                filters,
                forceReplace = false,
                checkNotSeen = false,
                shared = false,
            },
        ) => {
            const user = store.getters.user;

            if (!user) {
                return null;
            }

            const reports = store.getters.reports;

            const filtersString = convertObjectToUrlQueryParams(
                filters,
            ).replace('?', '');

            if (!page || page < 1) {
                page = 1;
            }

            let response = null;

            try {
                response = await Api().get(
                    `game-reports?pageSize=${pageSize}&page=${page}&checkNotSeen=${checkNotSeen}&shared=${shared}&${filtersString}`,
                );
            } catch (err) {
                console.error('store::teacher::getReports error: ', err);

                response = null;
            }

            if (!response) {
                return null;
            }

            const { success, data } = response.data;

            let commitData = [];

            if (checkNotSeen) {
                return store.commit(
                    'hasNotSeenReports',
                    data.hasNotSeenReports,
                );
            }

            if (success) {
                const filteredData = data.filter((game) => !game.demo);

                commitData =
                    page === 1 || forceReplace
                        ? filteredData
                        : [...reports, ...filteredData];

                store.commit('reports', commitData);

                return filteredData;
            }

            return null;
        },
        loadSingleReport: async (store, { code, type }) => {
            try {
                const response = await Api().get(
                    `game-reports/singleReport/${type}/${code}`,
                );

                const { success, error, data } = response.data || {};

                if (success && data) {
                    store.commit('currentReport', data);
                    store.commit('updateReport', data);
                } else if (error) {
                    console.error(
                        'teacher::loadSingleReport server error',
                        error,
                    );
                }
            } catch (err) {
                console.error('teacher::loadSingleReport error', err);
            }
        },
        markReportAsViewed: async (store, { gameCode, gameType }) => {
            try {
                const response = await Api().post(
                    `game-reports/viewReport/${gameType}/${gameCode}`,
                );

                const { error, success, data } = response.data;

                if (success && data) {
                    store.commit('currentReport', data);
                    store.commit('updateReport', data);

                    return data;
                } else if (error) {
                    console.error(
                        'teacher::markReportAsViewed server error',
                        error,
                    );
                }
            } catch (error) {
                console.error(
                    'teacher::markReportAsViewed request error: ' + error,
                );
            }
        },
        removePlayerFromReport: async (store, { report, player }) => {
            const gameCode = report.code;
            const module = report.flag === 'spg' ? 'self-paced-game' : 'game';
            const payload = {
                // backend remove player just by name
                // and because names should be unique per game -
                // it should be fine
                // but somehow one day may be it will be more
                // tracking on guest ids, and guest/user ids can be used
                // userId: player.userId,

                // because we post player name - no need to base64 it
                name: player.name,
            };

            try {
                const response = await Api().post(
                    `${module}/remove-player/${gameCode}`,
                    payload,
                );

                const { error, success, data } = response.data;

                if (success && data) {
                    store.commit('currentReport', data);
                    store.commit('updateReport', data);
                } else if (error) {
                    console.error(
                        'teacher::removePlayerFromReport server error',
                        error,
                    );
                }
            } catch (err) {
                console.error('teacher::removePlayerFromReport error', err);
            }
        },
        reloadSkillReports: async (store) => {
            const categories = getSkillList.filter(
                (item) =>
                    Number(item.grade) === Number(store.state.activeGrade),
            );

            let skillListArray = [
                ...getFactFluencyList,
                ...(categories[0] || getSkillList[0]).categories,
            ]
                .map((category) =>
                    category.topics
                        .map((topic) =>
                            topic.skills
                                .map((skill) => {
                                    return {
                                        type: skill.type,
                                        numberGenerator: skill.numberGenerator,
                                    };
                                })
                                .flat(),
                        )
                        .flat(),
                )
                .flat();

            await store.dispatch('loadSkillReports', {
                classCode:
                    store.state.activeClass &&
                    store.state.activeClass.classCode !== '-1'
                        ? store.state.activeClass.classCode
                        : null,
                skillData: skillListArray,
            });
        },
        loadSkillReports: async (store, { classCode, skillData }) => {
            const user = store.getters.user;

            const uid = user ? user.userId : null;

            const cachedReportsJson = localStorage.getItem(
                `getReportsFromSkills/${uid}/${classCode}`,
            );

            const cachedReports = cachedReportsJson
                ? JSON.parse(cachedReportsJson)
                : [];

            // filter out only skills which we don't have cached
            const filteredSkillData = skillData.filter(
                (skill) =>
                    !cachedReports.some(
                        (cachedSkill) =>
                            cachedSkill.type === skill.type &&
                            isEqual(
                                cachedSkill.numberGenerator,
                                skill.numberGenerator,
                            ),
                    ),
            );

            if (!filteredSkillData.length) {
                store.commit('skillsReports', cachedReports);

                return;
            }

            if (!user || !uid) return;

            let response = null;

            try {
                response = await Api().post(
                    `game-reports/game-stats-per-skill-improved/${
                        classCode ? classCode : ''
                    }`,
                    JSON.stringify(filteredSkillData),
                );
            } catch (err) {
                console.error('store::teacher::loadSkillReports error: ', err);

                response = null;
            }

            if (!response) {
                return null;
            }

            const { success, data } = response.data;

            if (!success) {
                return null;
            }

            let cachedData = [];

            try {
                cachedData =
                    JSON.parse(
                        localStorage.getItem(
                            `getReportsFromSkills/${uid}/${classCode}`,
                        ),
                    ) || [];
            } catch (err) {
                console.error(
                    'store::teacher::loadSkillReports cached error: ',
                    err,
                );

                cachedData = [];
            }

            cachedData.push(...data.filter((report) => !!report?._id));

            localStorage.setItem(
                `getReportsFromSkills/${uid}/${classCode}`,
                JSON.stringify(cachedData),
            );

            store.commit('skillsReports', cachedData);

            // return cachedData;
        },
        loadActiveClass: async (store) => {
            if (store.state.activeClass) return;

            const aClass =
                store.rootGetters.user?.activeClass || store.state.classList[0];

            store.commit('activeClass', aClass);
        },
        setActiveClass: async (store, classCode) => {
            const user = store.getters.user;

            if (!user) return;

            const selectedClass =
                classCode !== '-1'
                    ? store.state.classList.find(
                          (item) => item.classCode === classCode,
                      )
                    : {
                          classCode: '-1',
                          className: i18n.t('general.noClass'),
                          grade: '1',
                      };

            if (!selectedClass) {
                console.error(
                    `store::teacher::setActiveClass - class ${classCode} not found`,
                );
                store.commit('activeClass', null);
                return;
            }

            store.commit('activeClass', selectedClass);

            let response;

            try {
                response = await Api().post(
                    `/user/set-active-class`,
                    JSON.stringify(selectedClass),
                );
            } catch (err) {
                console.error(
                    'store::teacher::setActiveClass - save to BE error',
                    err,
                );

                response = null;
            }

            if (!response) return;

            const { success, error, data } = response.data;

            if (success && data) {
                store.dispatch(
                    'v2/user/update',
                    { teacherInfo: data },
                    { root: true },
                );
            } else {
                console.error(
                    'store::teacher::setActiveClass - save to BE server error',
                    error,
                );
            }

            const classGrade = store.state.gradesList.includes(
                Number(selectedClass.grade),
            )
                ? Number(selectedClass.grade)
                : 1;

            store.dispatch('setActiveGrade', classGrade);
        },
        syncCleverClassroom: async (store, classroom) => {
            const windowHeight = window.outerHeight;

            const windowWidth = window.outerWidth;

            const left = windowWidth / 2 - 375;

            const top = windowHeight / 2 - 375;

            let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
            width=750,height=750,left=${left},top=${top}`;

            window.open(store.getters.cleverAuthLink, 'Clever Auth', params);

            window.popupCallback = async (popupData) => {
                const code = popupData.cleverCode;

                store
                    .dispatch('viewCleverClassroom', code, {
                        root: true,
                    })
                    .then(async (_classrooms) => {
                        const cleverClassroomToSync = _classrooms.find(
                            (section) =>
                                section.sectionId === classroom.sectionId,
                        );

                        await store.dispatch('handleCleverSync', {
                            classroom,
                            cleverClassroomToSync,
                        });
                    })
                    .catch((err) => console.error(err));
            };
        },
        handleCleverSync: async (
            store,
            { classroom, cleverClassroomToSync },
        ) => {
            try {
                const result = await Api().post(
                    'class-lists/sync-clever-classroom',
                    { ...classroom, ...cleverClassroomToSync },
                );

                const { success } = result.data;

                if (success) {
                    app.config.globalProperties.$emitter.emit(
                        'updateClassroom',
                    );

                    await store.dispatch('loadClassLists', {
                        skipLocalCache: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        },
        syncGoogleClassroom: async (store, classroom) => {
            const googleClassroomScopes = [
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/classroom.courses.readonly',
                'https://www.googleapis.com/auth/classroom.rosters.readonly',
            ].join(' ');

            const googleUser = await store.dispatch(
                'googleAuthWithToken',
                {
                    scopes: googleClassroomScopes,
                },
                { root: true },
            );

            if (!googleUser?.token) {
                alert('Unable to access Google. Please try again');

                return;
            }

            const accessToken = googleUser.token;

            store
                .dispatch(
                    'viewGoogleClassroom',
                    { accessToken },
                    { root: true },
                )
                .then(async (_classrooms) => {
                    const googleClassroomToSync = _classrooms.find(
                        (_classroom) =>
                            `${_classroom.courseId}` ===
                            `${classroom.courseId}`,
                    );

                    await store.dispatch('handleGoogleSync', {
                        classroom,
                        googleClassroomToSync,
                    });
                })
                .catch((err) => console.error(err));
        },
        handleGoogleSync: async (
            store,
            { classroom, googleClassroomToSync },
        ) => {
            try {
                const result = await Api().post(
                    'class-lists/sync-google-classroom',
                    { ...classroom, ...googleClassroomToSync },
                );

                console.debug({ result });

                const { success } = result.data;

                if (success) {
                    app.config.globalProperties.$emitter.emit(
                        'updateClassroom',
                    );

                    await store.dispatch('loadClassLists', {
                        skipLocalCache: true,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        },
        hideSchoolPracticeWelcome: () => {
            sessionStorage.setItem('hideSchoolPracticeWelcome', 'true');
        },
        /**
         * SPG assignment may contain several skills known as a Playlist.
         * In the current implementation playlist is stored in sessionStorage.
         * todo: refactor to store
         * todo: make use of this method in all places it is copy-pasted
         * todo: create a map method to add entire playlist
         * This method adds a given skill to playlist and re-saves.
         * the playlist to the session storage.
         * @param {Object} _ctx Vuex store context
         * @param {Object} payload A dataset of a skill to be added.
         * @param {Object} payload.skill - The skill object.
         * @param {string} payload.category - The skill category. May also be stored in the skill object.
         * @param {Object} payload.option - Task type (# of problems or duration).
         * @param {string} payload.gameOriginInUI - The origin of the game in the UI.
         * @param {'default'|'bonus'} payload.listType - A list the skill is being selected from.
         */
        addSkillToPlaylist(
            _ctx,
            { skill, category, option, gameOriginInUI, listType },
        ) {
            let playlistObj = JSON.parse(
                sessionStorage.getItem('spgPlaylist') || '[]',
            );

            let obj = {
                gameType: {
                    type: skill.type,
                    // backend requires `equationType` for the game creation
                    // todo: revise the architecture
                    equationType: getGameTypeEquationIcon(skill.type),
                    numberGenerator: skill.numberGenerator,
                    language: _ctx.rootGetters.getCurrentLanguage,
                },
                options: option || { questionCount: 15 },
                metaData: {
                    topicName: category || skill.category,
                    presetName: skill.name,
                    // todo: what am I? consider removal
                    gameOriginInUI,
                    isExtraSkill: listType === 'bonus',
                },
            };

            playlistObj.push(obj);

            sessionStorage.setItem('spgPlaylist', JSON.stringify(playlistObj));
        },
        async removeStudent(store, { classCode, studentId, otherTeacher }) {
            try {
                const response = await SecureApi().delete(
                    `/class-lists/${classCode}/remove-student/${studentId}?otherTeacher=${otherTeacher ? true : ''}`,
                );

                if (response.data.success) {
                    return true;
                } else {
                    console.error('rS server error: ' + response.data.error);

                    store.dispatch(
                        'v2/ui/alert',
                        'Something went wrong. Please contact us if this continues',
                        {
                            root: true,
                        },
                    );
                }
            } catch (error) {
                console.error('rS request error: ' + error);

                store.dispatch(
                    'v2/ui/alert',
                    'Something went wrong. Please contact us if this continues',
                    {
                        root: true,
                    },
                );
            }
            return false;
        },
        setPreselectForAssignmentFromReport(
            store,
            { gameMode, gameType, metaData },
        ) {
            // To set preselects to AssignmentSetup.vue we need to set data to
            // 'setSelectedTblCategories' and 'setSelectedTblSkills'.

            // But in report we have the data available in a different format.
            // So now we try to see if we have the data first and then
            // after that try to set the data for the preselects.
            // The names of fields do not match between the report and the
            // setup page as they are done in different "eras".

            const gameOriginInUI =
                gameMode === CONSTANTS.LIVE_GAME_MODE
                    ? metaData?.gameOriginInUI
                    : metaData?.gameOriginInUIDetail;

            if (gameOriginInUI) {
                // 'setSelectedTblCategories' expects the value in
                // 'topic-group<skill-list/curriculum-set>::<topic-name>
                // eg 'skill-list::Subtraction

                const formattedTopicName = firstToUpper(
                    gameType.name
                        ? gameType.name
                        : gameType.type.replace('TYPE_', '').replace(/_/g, ' '),
                );

                const topicMapValue = `${gameOriginInUI}::${formattedTopicName}`;
                store.commit('setSelectedTblCategories', [topicMapValue]);

                // 'setSelectedTblSkills' expects to get the "subtopic" or in
                // the new terms "category"->"topic" as the key and skill name
                // as value. <subtopic aka topic>::<skillName>
                // This information is not always present, as games can be
                // started from the custom configurator as well and in there
                // the skills don't have names.
                // Eg 'Subtracting 2 numbers::Subtract multiples of 10'
                if (gameOriginInUI && metaData?.presetName) {
                    const category =
                        store.getters.topicsByGroupAndCategoryMap[
                            topicMapValue
                        ];
                    let topicName;

                    for (let categoryTopic of category) {
                        categoryTopic.skills.some((skill) => {
                            if (skill.name === metaData?.presetName) {
                                topicName = categoryTopic.topic;
                                return true;
                            }
                        });

                        if (topicName) {
                            // return early if we have found the right skill.
                            break;
                        }
                    }

                    if (topicName) {
                        store.commit('setSelectedTblSkills', [
                            `${topicName}::${metaData?.presetName}`,
                        ]);
                    }
                }
            }
        },
    },
};
